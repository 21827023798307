import React from 'react'
import classnames from 'classnames'
import { Button, Icon } from 'semantic-ui-react'

import SEO from '../components/SEO'
import Vimeo from '../components/Vimeo'

import Logo from '../images/logos/contego-logo.png'

const VIDEOS = [
  {
    title: 'Competitive Advantages Through Contego Spa',
    id: '495428207',
    frameRatio: 9 / 16
  },
  {
    title: 'Ivy Nails Spa - Majestic Experience',
    id: '524144137',
    frameRatio: 9 / 16
  },
  {
    title: "Ivy Nails Spa - What's New",
    id: '524143799',
    frameRatio: 9 / 16
  },
  {
    title: 'Ivy Nails - About Us',
    id: '524144457',
    frameRatio: 9 / 16
  },
  {
    title: 'Ivy Nails Spa - Tour',
    id: '524144737',
    frameRatio: 9 / 16
  },
  {
    title: 'AQ Nails - Tour',
    id: '524142993',
    frameRatio: 9 / 16
  },
  {
    title: 'AQ Nails - First Impressions',
    id: '524143521',
    frameRatio: 9 / 16
  },
  {
    title: 'AQ Nails - Client',
    id: '524143201',
    frameRatio: 9 / 16
  },
  {
    title: 'Nail Retreat - Clients',
    id: '524145360',
    frameRatio: 9 / 16
  },
  {
    title: 'Idol Nails - Clients',
    id: '524142423',
    frameRatio: 9 / 16
  },
  {
    title: 'Queen Nails - Clients',
    id: '524145078',
    frameRatio: 9 / 16
  },
  {
    title: 'Escape Nails & Lash Studio',
    id: '495431902',
    frameRatio: 9 / 16
  },
  {
    title: 'Q Nails & Spa',
    id: '486276088',
    frameRatio: 9 / 16
  },
  {
    title: 'Beverly Nails Salon',
    id: '486276337',
    frameRatio: 9 / 16
  },
  {
    title: 'Paradise Tomball Nails & Spa',
    id: '486276140',
    frameRatio: 9 / 16
  },
  {
    title: 'Paradise Nails & Spa TC Jester',
    id: '486276174',
    frameRatio: 9 / 16
  },
  {
    title: 'Executive Nails Louetta Spring',
    id: '486276285',
    frameRatio: 9 / 16
  },
  {
    title: 'Rumors Nails & Spa',
    id: '486276048',
    frameRatio: 9 / 16
  },
  {
    title: 'D&D Nails & Spa',
    id: '486276310',
    frameRatio: 9 / 16
  },
  {
    title: 'Infinite Nails & Spa',
    id: '486276220',
    frameRatio: 9 / 16
  },
  {
    title: 'Tour of Infinite Nails & Spa',
    id: '482494003',
    frameRatio: 9 / 16
  },
  {
    title: 'Tour of D&D Nails Spa',
    id: '482493637',
    frameRatio: 9 / 16
  },
  {
    title: 'Tour of Executive Nails Louetta Spring',
    id: '482493796',
    frameRatio: 9 / 16
  },
  {
    title: 'Tour of Q Nails & Spa',
    id: '495438353',
    frameRatio: 9 / 16
  },
  {
    title: 'No.1 Nail Bar',
    id: '495445383',
    frameRatio: 9 / 16
  },
  {
    title: 'No.1 Nail Bar TVC',
    id: '495447095',
    frameRatio: 9 / 16
  },
  {
    title: 'Rio Nails - Clients',
    id: '524142713',
    frameRatio: 9 / 16
  },
  {
    title: 'JoJo Nails TVC',
    id: '495447836',
    frameRatio: 9 / 16
  },
  {
    title: 'La Perla Nails & Spa',
    id: '495448602',
    frameRatio: 9 / 16
  },
  {
    title: 'Dream Nails',
    id: '495449106',
    frameRatio: 9 / 16
  },
  {
    title: 'Coming Soon Rio Nail Lounge',
    id: '495449985',
    frameRatio: 9 / 16
  },
  {
    title: 'Coming Soon The Nail Lounge',
    id: '495450483',
    frameRatio: 9 / 16
  }
]

const Clients = () => {
  const goToTop = React.useCallback(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [])

  return (
    <div className="container">
      <SEO
        title="CONTEGO FAMILY"
        description="Contego Family welcomes you to explore our member success stories."
        meta={[
          {
            name: 'keywords',
            content: 'Contégo, spa chair, pedicure spa chair, nail, nail salon, pedicure sanitation'
          }
        ]}
      />

      <div className="row">
        <div className="col py-4">
          <img
            src={Logo}
            width="150px"
            style={{ display: 'block', margin: '1rem auto' }}
            height="auto"
            alt="contego company logo"
          />

          <h2 style={{ textAlign: 'center' }}>WELCOME TO CONTÉGO FAMILY</h2>
        </div>
      </div>

      {VIDEOS.map((video, index) => {
        return (
          <div
            className={classnames([
              'row',
              index > 0 && 'my-4',
              index % 2 === 0 ? 'bg-alabaster' : 'bg-light'
            ])}
            key={video.title}
          >
            <div className="col px-0">
              {video.title && (
                <h2 className="w-100 text-center py-4 my-0 text-secondary px-2">{video.title}</h2>
              )}
              <Vimeo
                id={video.id}
                title={video.title}
                frameRatio={video.frameRatio}
                threshold={[0, 1]}
              />
            </div>
          </div>
        )
      })}

      <div className="row">
        <div className="col px-0">
          <h2 className="text-center bg-alabaster text-secondary px-4 py-4 my-0">
            <br />
            CONTÉGO FAMILY <br />
            WELCOME YOU ONBOARD TO <br />
            THE PROSPEROUS LAND !!!
            <br /> <br />
            <br />
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col px-0">
          <div className="w-100 d-flex flex-wrap py-2 justify-content-center">
            <a href="https://www.contegospa.com/privacy" className="my-4 mr-4">
              Privacy Policy
            </a>

            <a href="https://www.contegospa.com/digital-catalog/" className="my-4 mr-4">
              Digital Catalog
            </a>
          </div>
        </div>
      </div>

      <Button icon size="big" basic onClick={goToTop} className="rounded-circle back-to-top-btn">
        <Icon name="angle up" />
      </Button>
    </div>
  )
}

export default Clients
